.projects {
    padding-bottom: 100px;
}

.projects__list {
    padding: 0 40px;
    margin-top: 50px;
    display: flex;
    gap: 40px;
    flex-wrap: wrap;
    justify-content: center;
}

.project {
    max-width: 400px;
    width: 100%;
    height: 250px;
    position: relative;
}

.project__img {
    cursor: pointer;
    width: 100%;
    height: 100%;
}

.project__img > img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.project__overlay {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
}

.project:hover > .project__overlay {
    display: flex;
}

.project-modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.project-modal__content  {
    background-color: white;
    max-width: 400px;
    height: 400px;
    width: 100%;
    position: relative;
    padding: 20px;
}

.project-modal__close {
    position: absolute;
    top: 5px;
    right: 5px;
}

.project-modal__img {
    width: 100%;
    height: 200px;
}

.project-modal__img > img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.project-modal__content h3 {
    color: black;
    font-size: 26px;
}

.project-modal__content p {
    color: black;
    padding: 10px;
    margin: 10px;
}

.project-modal__content p:first-child {
    /* Add your custom styles for the first item here */
    padding-top: 0px; /* Adjust the top padding */
    padding-bottom: 0px; /* Adjust the bottom padding */
    margin-top: 0px; /* Adjust the top margin */
    margin-bottom: 3px; /* Adjust the bottom margin */
}

#special-p {
    /* Add your custom styles for the nested <p> element here */
    padding-top: 0px; /* Adjust the top padding */
    padding-bottom: 0px; /* Adjust the bottom padding */
    margin-top: 0px; /* Adjust the top margin */
    margin-bottom: 2px; /* Adjust the bottom margin */
}
