/* Float Shadow Phaser */
.skills__icons .shadow {
  /* display: inline-block; */
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(173, 37, 37, 0.405);
  position: relative;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  background: radial-gradient(
    ellipse at center,
    rgba(252, 0, 0, 0.779) 0%,
    rgba(198, 19, 10, 0.173) 80%);
  border-radius: 50%
}
.skills__icons .shadow:before {
  pointer-events: none;
  position: absolute;
  z-index: -1;
  content: "";
  top: 100%;
  left: 5%;
  height: 10px;
  width: 90%;
  opacity: 1;
  background: -webkit-radial-gradient(
    center,
    ellipse,
    rgb(98, 65, 65) 0%,
    rgba(41, 28, 28, 0) 80%
  );
  background: radial-gradient(
    ellipse at center,
    rgba(0, 0, 0, 0.895) 0%,
    rgba(148, 204, 26, 0.985) 80%
  );
  /* W3C */
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform, opacity;
  transition-property: transform, opacity;
}
.skills__icons .shadow:hover,
.skills__icons .shadow:focus,
.skills__icons .shadow:active {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
  background: -webkit-radial-gradient(
    center,
    ellipse,
    rgba(0, 0, 0, 0.35) 0%,
    rgba(0, 0, 0, 0) 80%
  );
  background: radial-gradient(
    ellipse at center,
    rgba(252, 0, 0, 0.779) 0%,
    rgb(198, 20, 10) 80%);
  border-radius: 50%;
  /* move the element up by 5px */
}
.skills__icons .shadow:hover:before,
.skills__icons .shadow:focus:before,
.skills__icons .shadow:active:before {
  opacity: 1;
  -webkit-transform: translateY(5px);
  transform: translateY(5px);
  /* move the element down by 5px (it will stay in place because it's attached to the element that also moves up 5px) */
}
/* dev icons*/


.skills__icons i, .skills__icons img {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(135, 128, 128, 0);
    position: relative;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
  }
  .skills__icons i:before , .skills__icons img:before {
    pointer-events: none;
    /* position: absolute; */
    z-index: -1;
    /* content: ""; */
    top: 100%;
    left: 5%;
    height: 10px;
    width: 90%;
    opacity: .5;
    background: -webkit-radial-gradient(
      center,
      ellipse,
      rgba(0, 0, 0, 0.35) 0%,
      rgba(0, 0, 0, 0) 80%
    );
    background: radial-gradient(
      ellipse at center,
      rgba(252, 0, 0, 0.779) 0%,
      rgb(198, 20, 10) 80%
      
    );
    border-radius: 50%; 

    /* W3C */
     -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform, opacity;
    transition-property: transform, opacity;
  }
  .skills__icons i:hover,
  .skills__icons i:focus,
  .skills__icons i:active,
  .skills__icons img:hover,
  .skills__icons img:focus,
  .skills__icons img:active {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
    background-color: rgba(228, 217, 217, 0.209);
    border-radius: 50%; 
    /* move the element up by 5px */
   }
  .skills__icons i:hover:before,
  .skills__icons i:focus:before,
  .skills__icons i:active:before,
  .skills__icons img:hover:before,
  .skills__icons img:focus:before,
  .skills__icons img:active:before
   {
    opacity: 1;
    -webkit-transform: translateY(5px);
    transform: translateY(5px); 
    /* move the element down by 5px (it will stay in place because it's attached to the element that also moves up 5px) */
   } 
  