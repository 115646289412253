.skills {
  padding-left: 20px;
  padding-right: 20px;
}
#skills {
  display: block;
}

.section__heading {
  padding-top: 60px;
}

/* .textSkills{
  margin-top: ;
} */
.skills__icons {
  /* max-width: 60vw; */
  margin: 80px auto;
  color: white;
  font-size: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
/* .skills__icons i:nth-child(5),
.skills__icons i:nth-child(6),
.skills__icons i:nth-child(7) {
  grid-column: span 33%;
} */



.skills__icons i,
.skills__icons img {
  margin-top: 70px;
  margin: 45px;
}

/* .skills__icons i:hover{
 background-color: rgba(228, 217, 217, 0.209);
 border-radius: 50%;
} */

.mainGameIcons {
  display: flex;
  width: 90%;
  align-items: center;
  /* justify-content: center; */
  /* justify-content: space-between; */
  justify-content: space-around;
  align-items: flex-start;
}

/* .UNITY {
  align-items: center; */
/* margin-left: auto;
  margin-right: auto;
  width: 50%;
  align-self: center;
  justify-content: center;*/
/* }  */

/* .HTML5GAMES {
  align-items: center; */
/* margin-left: auto;
  margin-right: auto;
  width: 50%;
  align-self: center;
  margin-left: 50%;
  justify-content: space-between;

  justify-content: center;*/
/* }  */
/* 
.UNITYcenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}
.HTMLcenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
} */

.skills1,
.skills2,
.skills3,
.skills4 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 100px;
}
.right{
  font-size: 1rem;
}

@media (max-width: 520px) {
  .skills__icons div {
    align-items: center;
    flex-direction: column;
  }
  /* resize skills icons for smart phones */
}

@media (max-width: 580px) {
  /* .skills__icons img, .skills__icons i{
   margin: 30px;
  } */
  .skills4 img{
    width: 200px;
  }
  .skills__icons i{
    width: 65px;
  }
  .skills3 img{
    width: 100px;
    height: auto;
  }
  /* resize skills icons for smart phones */
}
@media (max-width: 640px) and (min-width: 581px) {
  /* .skills__icons img, .skills__icons i{
   margin: 30px;
  } */
  .skills__icons i{
    width: 80px;
  }
  .skills3 img{
    width: 100px;
    height: auto;
  }
  /* resize skills icons for smart phones */
}

@media (max-width: 720px) and (min-width: 581px) {
  /* .skills__icons img, .skills__icons i{
   margin: 30px;
  } */
  .skills4 img{
    width: 230px;
  }
  /* resize skills icons for smart phones */
}

@media (max-width: 780px) {
  .skills__icons img, .skills__icons i{
   margin: 30px;
  }
  /* resize skills icons for smart phones */
}

