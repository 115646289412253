.header{
    background-color: rgb(25, 22, 43);
    display:flex;
    justify-content: space-between;
    padding: 20px 30px;
    position: sticky;
    top: 0;
    z-index: 100;
}

.logo {
    font-size: 18px;
}

.nav {
    display: flex;
    gap: 20px;
}

.nav > a {
    text-decoration: none;
    color: white;
    font-size: 18px;
}